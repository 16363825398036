const ARTICLE_SLIDER = `

<div class="scr-custom-slider-in-article">
  <div class="slider">
    <div class="slider-wrapper">
      <img src="https://wp.screver.com/wp-content/uploads/2023/11/image-1229.jpg" class="slide">
      <img src="https://wp.screver.com/wp-content/uploads/2023/11/image-1230.png" class="slide">
      <img src="https://wp.screver.com/wp-content/uploads/2023/11/IMG_1255.jpg" class="slide">
    </div>
    <button class="prev-slide">
      <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_b_3917_2426)">
          <rect width="52" height="52" rx="26" transform="matrix(-1 0 0 1 52 0)" fill="black" fill-opacity="0.5" />
          <path d="M29.5 17.8332L21.3333 25.9998L29.5 34.1665" stroke="white" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round" />
        </g>
        <defs>
          <filter id="filter0_b_3917_2426" x="-4" y="-4" width="60" height="60" filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_3917_2426" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_3917_2426" result="shape" />
          </filter>
        </defs>
      </svg>
    </button>
    <button class="next-slide">
      <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_b_3917_2425)">
          <rect width="52" height="52" rx="26" fill="black" fill-opacity="0.5" />
          <path d="M22.5 17.8332L30.6667 25.9998L22.5 34.1665" stroke="white" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round" />
        </g>
        <defs>
          <filter id="filter0_b_3917_2425" x="-4" y="-4" width="60" height="60" filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_3917_2425" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_3917_2425" result="shape" />
          </filter>
        </defs>
      </svg>

    </button>
    <div class="slide-indicators">
      <span class="slide-indicator active"></span>
      <span class="slide-indicator"></span>
      <span class="slide-indicator"></span>
    </div>
  </div>
  <style>
    .scr-custom-slider-in-article .slider {
      position: relative;
      overflow: hidden;
      border-radius: 24px;
      height: 100vh;
    }

    .scr-custom-slider-in-article .slider-wrapper {
      display: flex;
      transition: transform 0.5s ease-in-out;
      height: 100%;
    }

    .scr-custom-slider-in-article .slide {
      flex-shrink: 0;
      width: 100%;
      object-fit: cover;
    }

    .scr-custom-slider-in-article .prev-slide,
    .scr-custom-slider-in-article .next-slide {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: transparent;
      border: none;
      color: #fff;
      font-size: 2em;
      cursor: pointer
    }

    .scr-custom-slider-in-article .prev-slide svg,
    .scr-custom-slider-in-article .next-slide svg {
      transition: all ease-in 0.1s
    }

    .scr-custom-slider-in-article .prev-slide:hover svg,
    .scr-custom-slider-in-article .next-slide:hover svg {
      transform: scale(1.1)
    }

    .scr-custom-slider-in-article .prev-slide {
      left: 10px
    }

    .scr-custom-slider-in-article .next-slide {
      right: 10px
    }

    .scr-custom-slider-in-article .slide-indicators {
      display: flex;
      justify-content: center;
      z-index: 1;
      position: absolute;
      right: 0;
      bottom: 20px;
      left: 0
    }

    .scr-custom-slider-in-article .slide-indicator {
      width: 6px;
      height: 6px;
      border-radius: 100px;
      background-color: rgba(255, 255, 255, .3);
      margin: 0 5px;
      cursor: pointer;
      transition: all 0.2s linear
    }

    .scr-custom-slider-in-article .slide-indicator.active {
      width: 36px;
      background-color: #fff
    }

    @media screen and (max-width: 576px) {
      .scr-custom-slider-in-article .prev-slide svg,
      .scr-custom-slider-in-article .next-slide svg {
        width: 32px;
        height: 32px;
      }

      .scr-custom-slider-in-article .slide-indicator {
        margin: 0 2px;
      }
    }
  </style>

  <script>
    const slider = document.querySelector('.slider');
    const slides = slider.querySelectorAll('.slide');
    const slideWrapper = slider.querySelector('.slider-wrapper');
    const prevBtn = slider.querySelector('.prev-slide');
    const nextBtn = slider.querySelector('.next-slide');
    const slideIndicators = slider.querySelectorAll('.slide-indicator');
    let currentSlideIndex = 0;
    const numSlides = slides.length;

    function goToSlide(slideIndex) {
      if (slideIndex < 0 || slideIndex >= numSlides) return;
      slideWrapper.style.transform = \`translateX(-\${slideIndex * 100}%)\`;
      slideIndicators[currentSlideIndex].classList.remove('active');
      currentSlideIndex = slideIndex;
      slideIndicators[currentSlideIndex].classList.add('active')
    }
    prevBtn.addEventListener('click', () => goToSlide(currentSlideIndex - 1));
    nextBtn.addEventListener('click', () => goToSlide(currentSlideIndex + 1));
    slideIndicators.forEach((indicator, i) => {
      indicator.addEventListener('click', () => goToSlide(i))
    });
    goToSlide(currentSlideIndex)
  </script>
</div>
`;

export default ARTICLE_SLIDER;
